.navbarContainer {
    display: block;
}

.headerContact {
    display: flex;
    align-items: center;
    gap: 20px;
}

.navbarTop {
    position: fixed;
    top: 0;
    height: 25px;
    margin-top: 5px;
    width: 100%;
    background-color: rgb(255, 255, 255);
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    z-index: 10;
}

.navbarBottom{
    position: fixed;
    top: 25px;
    width: 100%;
    height: 100px;
    background-color: #f25700;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 9;
}

.section {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.logo {
    margin-left: 50px;
    height: 100%;
    width: auto;
}

ul {
    list-style-type: none; 
    margin: 0; 
    padding: 0; 
    display: flex; 
    align-items: center;
    margin-right: 50px;

}

li {
    margin: 20px;
    font-size: 20px;
    color: azure;
    font-weight: bold;
}