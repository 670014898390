footer {
    height: 60vh;
    width: 100%;
    background-color: black;
    display: flex;
    color: azure;
}

.logoFooterContainer {
    height: 300px;
    width: 400px;
    margin-top: 20px;
    margin-left: 50px;
}

.logoFooter {
    height: 80px;
    width: auto;
}

.contactListContainer {
    width: 300px;
    margin-top: 20px;
    height: auto;
}

.contactListContainer ul {
    display: flex;
    flex-direction: column;
    align-items: start;
}

.contactData {
    font-size: 15px;
}

.contactListForm {
    margin-top: 20px;
    margin-left: 250px;
    height: auto;
    width: 500px;
    display: flex;
    flex-direction: column;
}

.contactListForm form {
   display: block;
}

.contactListForm input {
    margin: 5px;
    width: 200px;
    height: 30px;
}

.contactListForm textarea {
    margin: 5px;
    width: 415px;
    height: 150px;
}

.contactListForm button {
    margin: 10px 5px; 
    width: 100px; 
    height: 40px;
    background-color: #f25700;
    color: white;
    border: none; 
    cursor: pointer;
    font-size: 16px;
}

.contactListForm h2 {
    margin: 20px 0;
    font-size: 30px;
}
